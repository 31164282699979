<template>
  <div>
    <CHeader position="sticky" class="mb-4">
      <CContainer fluid>
        <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
          <CIcon icon="cil-menu" size="lg" />
        </CHeaderToggler>
        <CHeaderBrand class="mx-auto d-lg-none" to="/">
          <CIcon :icon="logo" height="48" alt="Logo" />
        </CHeaderBrand>
          <!--<div class="input-group">
            <CFormInput
              style="width: 80%;"
              class="form-control"
              placeholder="Busca tus clientes aqui"
              v-model="search"
              @blur="onBlur"
              @focus="show_list = true"
            >
            </CFormInput>
            <CInputGroupText id="basic-addon1">
               <CIcon :icon="cilSearch"/>
            </CInputGroupText>
          </div>-->
          
        <!--<CHeaderNav class="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink href="/dashboard"> Dashboard </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Users</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Settings</CNavLink>
          </CNavItem>
        </CHeaderNav>-->
        <CHeaderNav>

          <!--
          <CNavItem>
            <CNavLink href="#">
              <CIcon class="mx-2" icon="cil-list" size="lg" />
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">
              <CIcon class="mx-2" icon="cil-envelope-open" size="lg" />
            </CNavLink>
          </CNavItem>-->
          <!-- <CDropdown variant="nav-item" style="margin-top: 10px;">
            <CDropdownToggle placement="bottom-end" class="py-0" :caret="true">
               <CIcon class="mx-2" icon="cil-bell" size="lg" />
            </CDropdownToggle>
            <CDropdownMenu class="pt-0">
              <CDropdownItem  v-for="(notification,key) in notifications" :key="key"> 
                <CIcon icon="cil-task" /> 
                <router-link class="a_href"
                  :to="'/'+notification.link"
                  >{{notification.data}}
                </router-link>
                <CDropdownDivider/>
              </CDropdownItem>
            </CDropdownMenu>
          </CDropdown> -->
          <AppHeaderDropdownAccnt />
        </CHeaderNav>
      </CContainer>
      <!-- <CHeaderDivider /> -->
      <!-- <CContainer fluid>
        <AppBreadcrumb />
      </CContainer> -->
       <CToaster placement="top-end" visible>
          <CToast
             v-for="not in notification"
            :key="'toast' + not.id"
            :show="true"
            header="Nueva notificación"
            
          >
            <CToastHeader closeButton></CToastHeader>
            <CToastBody>
              {{not.data}}
            </CToastBody> 
          </CToast>
      </CToaster>
    </CHeader>
    <CListGroup 
      id="list-customer"
      v-if="show_list && search.length >= 4" 
      style="position: absolute;z-index: 1200;background-color: #fff;width: 65%;margin-left: 90px;margin-top: -88px;"
    >
      <CListGroupItem v-for="customer in customers" :key="customer" style="cursor: pointer;" @click="selectCustomer(customer.id)">
        <CHeaderNavLink :id="'customer-'+customer.id" >
          {{customer.firstname +' '+customer.lastname}}
        </CHeaderNavLink>
      </CListGroupItem>
    </CListGroup>
  </div>
</template>

<script>
// import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import { logo } from '@/assets/brand/logo'
import { useUserStore } from '@/plugins/store';
import { cilSearch } from '@coreui/icons'

export default {
  name: 'AppHeader',
  components: {
    // AppBreadcrumb,
    AppHeaderDropdownAccnt,
  },
  setup() {

    const userStore = useUserStore();

    return {
      logo,
      userStore,
      cilSearch
    }
  },
  data(){
    return{
      user: null,
    }
  },
  mounted(){
    this.user = this.userStore.getUser

  },
  methods:{
  
  },
}
</script>
<style scoped>
  .input-group{
    width: 80% !important;
  }
    .a_href{
    color: inherit;
    text-decoration: none;
  }
</style>
