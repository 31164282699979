import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createPinia } from 'pinia';
import piniaPersist from 'pinia-plugin-persist'
import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import SmartTable from 'vuejs-smart-table'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'
import BackButton from '@/views/components/BackButton'
import Multiselect from '@vueform/multiselect'
import VueCal from 'vue-cal'
//import VueDatePicker from '@vuepic/vue-datepicker';
import './plugins/axios'
//import '@vuepic/vue-datepicker/dist/main.css'
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
import './styles/styles.css';
import 'vue-cal/dist/vuecal.css'
// Use plugin defaults (optional)


const pinia = createPinia()
pinia.use(piniaPersist)

const app = createApp(App)
app.use(pinia)
app.use(SmartTable)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(setupCalendar, {})

// Use the components
app.provide('icons', icons)
app.component('VCalendar', Calendar)
app.component('VDatePicker', DatePicker)
app.component('VueCal', VueCal)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)
app.component('BackButton', BackButton)
app.component('Multiselect', Multiselect)
//app.component('VueDatePicker', VueDatePicker);

app.mount('#app')
